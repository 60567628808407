.header-search {
  ::placeholder {
    color: white; // Default placeholder color
  }

  &:hover,
  &:focus-within {
    color: #4b5563;

    ::placeholder {
      color: #4b5563;
    }
  }
}
