.ant-btn {
    &.button-class {
        padding: 23px 20px;
        background-color: rgb(48 59 151 / var(--tw-bg-opacity));

        &:hover,
        &:focus {
            padding: 23px 20px;

            background: rgb(48 59 151 / var(--tw-bg-opacity)) !important;
            color: #fff !important;
            border-color: rgb(48 59 151 / var(--tw-bg-opacity)) !important;
        }
    }

    &.reset-class {
        padding: 20px 30px;
        border-radius: 50px;
        background-color: #fff;
        color: rgb(48 59 151 / var(--tw-bg-opacity));
        border-color: rgb(48 59 151 / var(--tw-bg-opacity));

        &:hover,
        &:focus {
            padding: 20px 30px;
            border-radius: 50px;
            background-color: #fff;
            color: rgb(48 59 151 / var(--tw-bg-opacity)) !important;
            border-color: rgb(48 59 151 / var(--tw-bg-opacity)) !important;
        }
    }

    &.submit-class {
        padding: 20px 30px;
        border-radius: 50px;
        background-color: #44A30D;
        color: #fff;
        border-color: #44A30D;

        &:hover,
        &:focus {
            padding: 20px 30px;
            border-radius: 50px;
            background-color: #44A30D !important;
            color: #fff !important;
            border-color: #44A30D !important;
        }
    }

    &.danger-class {
        padding: 20px 30px;
        border-radius: 50px;
        background-color: #D01313;
        color: #fff;
        border-color: #D01313;

        &:hover,
        &:focus {
            padding: 20px 30px;
            border-radius: 50px;
            background-color: #D01313 !important;
            color: #fff !important;
            border-color: #D01313 !important;
        }
    }

    &.direction-class {
        padding: 4px;
        border-radius: 6px;
        background-color: #fff;
        color: #fff;
        border-color: #C9C9C9;

        &:hover,
        &:focus {
            padding: 4px;
            border-radius: 6px;
            background-color: #fff !important;
            color: #fff !important;
            border-color: #C9C9C9 !important;
        }
    }

    &.close-main-class {
        padding: 4px;
        border-radius: 50%;
        background-color: rgb(48 59 151 / var(--tw-bg-opacity));
        color: #fff;
        border-color: rgb(48 59 151 / var(--tw-bg-opacity));

        &:hover,
        &:focus {
            padding: 4px;
            border-radius: 50%;
            background-color: rgb(48 59 151 / var(--tw-bg-opacity)) !important;
            color: #fff !important;
            border-color: rgb(48 59 151 / var(--tw-bg-opacity)) !important;
        }
    }

    &.cross-icon-class {
        padding: none;
        border-radius: 6px;
        background-color: transparent;
        color: #fff;
        box-shadow: unset;
        border-color: transparent;

        &:hover,
        &:focus {
            padding: none;
            border-radius: 6px;
            background-color: transparent !important;
            color: #fff !important;
            box-shadow: unset !important;
            border-color: transparent !important;
        }
    }
}