.influencer-main {
  .ant-tabs-nav {
    display: none !important;
  }
}

.influencer-modal {
  .ant-select-selection-item {
    color: #303b97;
  }

  .ant-select-selection-placeholder {
    color: #303b97;
  }
}
